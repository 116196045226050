body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FC788B;
  text-align: center;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}